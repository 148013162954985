// src/chat.js
import React, { useEffect, useState } from 'react';
import './chat.css';
import { apiFetch } from './api';

function Chat() {
  const [profileInfo, setProfileInfo] = useState({});
  const [chatList, setChatList] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [isRequestor, setIsRequestor] = useState(true); // 요청자(true), 수락자(false)
  const [newMessage, setNewMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [reservationDetails, setReservationDetails] = useState(null);
  const [showReservationModal, setShowReservationModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const accessToken = localStorage.getItem('accessToken'); // accessToken 가져오기

  // 채팅 목록 불러오기
  useEffect(() => {
    async function loadChatList() {
      try {
        const response = await apiFetch('/api/chat', {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        setChatList(data.list || []);
      } catch (error) {
        console.error('채팅 목록 불러오기 실패', error);
      }
    }

    loadChatList();
  }, [accessToken]);

  // 채팅방 상세 조회 및 메시지 불러오기
  const handleRoomClick = async (roomId) => {
    setSelectedRoom(roomId);
    try {
      const response = await apiFetch(`/api/room/${roomId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });
      const roomData = await response.json();

      if (roomData) {
        const messagesResponse = await apiFetch(`/api/room/${roomId}/messages`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });
        const messagesData = await messagesResponse.json();
        setMessages(messagesData || []);
      }
    } catch (error) {
      console.error('채팅방 상세 조회 실패', error);
    }
  };

  // 메시지 전송
  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;
    try {
      await apiFetch(`/api/chat`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ roomId: selectedRoom, content: newMessage }),
      });
      setMessages((prevMessages) => [
        ...prevMessages,
        { content: newMessage, sentByMe: true },
      ]);
      setNewMessage('');
    } catch (error) {
      console.error('메시지 전송 실패', error);
    }
  };

  // 예약 요청 모달 열기
  const openReservationModal = () => {
    setShowReservationModal(true);
  };

  // 리뷰 작성 모달 열기
  const openReviewModal = () => {
    setShowReviewModal(true);
  };

  return (
    <div className="chat-container">
      {/* 채팅 목록 */}
      <div className="chat-list">
        <div className="chat-list-header">
          <h2>채팅 목록</h2>
          <hr className="divider" />
          <ul id="chat-list-ul">
            {chatList.map((chat) => (
              <li
                key={chat.roomId}
                className="chat-item"
                onClick={() => handleRoomClick(chat.roomId)}
              >
                <img
                  src={chat.profileImage || '기본_프로필_이미지.png'}
                  alt="프로필"
                  className="profile-img"
                />
                <div className="chat-info">
                  <p>{chat.nickname}</p>
                  <span>
                    {chat.lastMessage.content.length > 20
                      ? chat.lastMessage.content.slice(0, 20) + '...'
                      : chat.lastMessage.content}
                  </span>
                  <span>{chat.lastMessage.timestamp}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* 채팅 창 */}
      <div className="chat-window">
        <div className="chat-header">
          <h2 id="chat-title">{profileInfo.nickname || '상대방의 닉네임'}</h2>
          <hr className="divider" />
        </div>

        {/* 메시지 창 */}
        <div className="chat-body">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`message ${message.sentByMe ? 'sent' : 'received'}`}
            >
              {message.content}
            </div>
          ))}
        </div>

        {/* 메시지 입력 창 */}
        <div className="chat-input">
          <input
            type="text"
            placeholder="메시지를 입력하세요."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
          />
          <button onClick={handleSendMessage}>보내기</button>
        </div>

        {/* 요청자/수락자 버튼 */}
        {isRequestor ? (
          <div className="chat-buttons">
            <button onClick={openReservationModal}>예약폼 보내기</button>
            <button disabled>예약 요청 상세보기</button>
            <button>사진 보내기</button>
            <button disabled>후기 작성하기</button>
          </div>
        ) : (
          <div className="chat-buttons">
            <button>사진 보내기</button>
            <button disabled>상세보기</button>
          </div>
        )}
      </div>

      {/* 상대방 프로필 정보 */}
      <div className="profile-section">
        {/* 프로필 정보 및 기타 UI */}
      </div>

      {/* 예약 요청 모달 */}
      {showReservationModal && (
        <div className="modal">
          <h2>예약 요청</h2>
          {/* 예약 요청 폼 */}
          <button onClick={() => setShowReservationModal(false)}>X</button>
        </div>
      )}

      {/* 리뷰 작성 모달 */}
      {showReviewModal && (
        <div className="modal">
          <h2>후기 작성</h2>
          {/* 후기 작성 폼 */}
          <button onClick={() => setShowReviewModal(false)}>X</button>
        </div>
      )}
    </div>
  );
}

export default Chat;
