import React from 'react';
import { savePost } from './api';
import { useNavigate } from 'react-router-dom';

function StepFour({ formData }) {
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('accessToken'); // 토큰 가져오기
      if (!token) {
        alert('로그인이 필요합니다.');
        navigate('/login');
        return;
      }

      // JSON 형식으로 데이터를 전송
      const dataToSubmit = {
        title: formData.title,
        content: formData.content,
        bug_type: formData.bug_type, // 변수명 수정
        bug_name: formData.bug_name, // 변수명 수정
        address: formData.address,
        address_detail: formData.address_detail, // 변수명 수정
        start_time: formData.start_time, // 변수명 수정
        end_time: formData.end_time, // 변수명 수정
        reward: formData.reward,
        originImageUrl: formData.originImageUrl,
        editedImageUrl: formData.editedImageUrl,
      };

      // API 호출
      await savePost(dataToSubmit);
      alert('게시글이 성공적으로 저장되었습니다.');
      navigate('/home'); // 저장 후 홈으로 이동
    } catch (error) {
      console.error("Error posting data:", error);
      alert("게시글 저장에 실패했습니다.");
    }
  };

  return (
    <div className="form-container success-container">
      <h2>잡아주세요 게시글 작성이 완료되었습니다.</h2>
      <img src="/images/jobpleasesuccess.svg" alt="게시글 작성 완료" className="success-image" />
      <button className="home-button" onClick={handleSubmit}>홈으로</button>
    </div>
  );
}

export default StepFour;
