import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import {jwtDecode} from 'jwt-decode';
import AddressSearch from './AddressSearch';
import './Register.css';
import { apiFetch } from './api';

const Register = () => {
    const [formData, setFormData] = useState({
        name: '', nickname: '', address: '', detailAddress: '', phone: ''
    });
    const [profilePicture, setProfilePicture] = useState(null);
    const [isNicknameAvailable, setIsNicknameAvailable] = useState(null);
    const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showAddressModal, setShowAddressModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const registerToken = queryParams.get('token'); 

        if (registerToken) {
            localStorage.setItem('registerToken', registerToken); 
            console.log('registerToken 저장됨:', registerToken);
        } else {
            console.error('URL에 registerToken이 없습니다.');
        }
    }, []);

    const onGoogleSuccess = (response) => {
        const token = response.credential;

        const isValidJwt = (token) => {
            const parts = token.split('.');
            return parts.length === 3; 
        };

        const decodeToken = (token) => {
            try {
                return jwtDecode(token); 
            } catch (e) {
                console.error("토큰 디코딩 중 오류 발생:", e);
                return null;
            }
        };

        if (isValidJwt(token)) {
            const decoded = decodeToken(token);
            if (decoded) {
                console.log('Google Login Success:', decoded);
                const fullName = `${decoded.family_name}${decoded.given_name}`;
                setFormData((prevFormData) => ({ ...prevFormData, name: fullName }));

                // 프로필 이미지 URL을 파일로 변환해 `profilePicture` 상태에 저장
                fetch(decoded.picture)
                    .then(res => res.blob())
                    .then(blob => {
                        const file = new File([blob], "profile.jpg", { type: blob.type });
                        setProfilePicture(file);
                        console.log('Profile Picture File:', file); // 파일 객체 확인
                    });

                setIsLoggedIn(true);
            } else {
                alert('로그인 중 문제가 발생했습니다. 다시 시도해주세요.');
            }
        } else {
            alert('잘못된 토큰입니다. 다시 시도해 주세요.');
        }
    };

    const checkNicknameAvailability = () => {
        const nickname = formData.nickname;
        const registerToken = localStorage.getItem('registerToken'); 

        console.log('Nickname to check:', nickname);

        if (nickname && registerToken) {
            apiFetch(`/api/user/duplicate?nickname=${nickname}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${registerToken}`,
                    'Content-Type': 'application/json'
                }
            })
            .then((data) => {
                if (data.code === 200) {
                    setIsNicknameAvailable(true);
                    console.log('Nickname is available.');
                }
            })
            .catch((error) => {
                if (error.message.includes('400')) {
                    setIsNicknameAvailable(false);
                    console.log('Nickname is already taken.');
                } else {
                    console.error("닉네임 중복 확인 에러:", error);
                }
            });
        } else {
            console.error("닉네임이나 registerToken이 없습니다.");
        }
    };

    const handleAddressSelect = (selectedAddress) => {
        console.log('Selected Address:', selectedAddress);
        setFormData((prevFormData) => ({
            ...prevFormData,
            address: selectedAddress
        }));
        setShowAddressModal(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value.trim()
        }));
        console.log(`Updated ${name}:`, value);
    };

    const handleSubmit = () => {
        const registerToken = localStorage.getItem('registerToken');

        if (isNicknameAvailable && isPrivacyChecked && registerToken) {
            const formDataToSend = new FormData();

            if (profilePicture) {
                formDataToSend.append('image', profilePicture);
                console.log('Added profile picture to FormData:', profilePicture); // 이미지 파일 확인
            } else {
                console.warn("Profile picture file is not set.");
            }

            // JSON 형식의 사용자 정보를 Blob으로 추가
            const userInfo = JSON.stringify({
                name: formData.name,
                nickname: formData.nickname,
                phone: formData.phone,
                addr: formData.address,
                detailAddr: formData.detailAddress
            });
            formDataToSend.append('userInfo', new Blob([userInfo], { type: 'application/json' }));
            console.log('FormData userInfo:', userInfo);

            // FormData 내용 확인
            apiFetch('/api/user/register', {
                method: 'POST',
                headers: {
                    'registerToken': `${registerToken}`
                    // Content-Type은 설정하지 않음 (FormData 전송 시 자동 설정)
                },
                body: formDataToSend
            })
            .then(response => {
                console.log("회원가입 성공:", response);
                localStorage.removeItem('registerToken');
                navigate('/login');
            })
            .catch(error => {
                console.error("회원가입 중 에러 발생:", error);
            });
        } else {
            console.error('회원가입 조건을 충족하지 않았습니다.');
            if (!isNicknameAvailable) console.log("닉네임 중복 확인 필요");
            if (!isPrivacyChecked) console.log("개인정보 처리방침 동의 필요");
            if (!registerToken) console.log("registerToken 없음");
        }
    };

    return (
        <div className="register-container">
            <h1>회원 가입</h1>

            {!isLoggedIn && (
                <GoogleOAuthProvider clientId="607941716422-goaocftar6hi9o8e0pv0roh8maqm34kk.apps.googleusercontent.com">
                    <GoogleLogin
                        onSuccess={onGoogleSuccess}
                        onError={(err) => console.error(err)}
                    />
                </GoogleOAuthProvider>
            )}

            <form>
                <label>이름</label>
                <input type="text" name="name" value={formData.name} readOnly />

                <label>닉네임</label>
                <div className="nickname-input">
                    <input type="text" name="nickname" onChange={handleChange} />
                    <button type="button" onClick={checkNicknameAvailability}>중복 확인</button>
                </div>
                {isNicknameAvailable === false && <p>이미 존재하는 닉네임입니다.</p>}
                {isNicknameAvailable === true && <p>사용 가능한 닉네임입니다.</p>}

                <label>주소</label>
                <button type="button" onClick={() => setShowAddressModal(true)}>새로운 주소 검색</button>
                <input type="text" name="address" value={formData.address} readOnly />

                <label>상세주소</label>
                <input type="text" name="detailAddress" onChange={handleChange} />

                <label>전화번호</label>
                <input type="text" name="phone" onChange={handleChange} />

                <div className="privacy-section">
                    <input type="checkbox" onChange={() => setIsPrivacyChecked(!isPrivacyChecked)} />
                    <label>
                        서비스 이용약관 및 <a href="https://leeinsunny.github.io/jobbug-privacy-policy/">개인정보처리방침</a>에 동의합니다.
                    </label>
                </div>

                <button type="button" onClick={handleSubmit}>회원가입</button>
            </form>

            {/* 주소 검색 모달 */}
            {showAddressModal && (
                <div className="modal">
                    <div className="modal-content">
                        <button className="close-modal" onClick={() => setShowAddressModal(false)}>닫기</button>
                        <AddressSearch onSelectAddress={handleAddressSelect} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Register;