const BASE_URL = 'https://api.jobbug.site'; 

export const apiFetch = (endpoint, options = {}, useRegisterToken = false) => {
  const token = useRegisterToken
    ? localStorage.getItem('registerToken')
    : localStorage.getItem('accessToken');

  const defaultOptions = {
    headers: {
      'Content-Type': 'application/json',
      ...(token && { 'Authorization': `Bearer ${token}` })
    },
    ...options
  };

  const url = `${BASE_URL}${endpoint}`;

  return fetch(url, defaultOptions)
    .then(response => {
      if (!response.ok) {
        console.error('HTTP 상태 코드:', response.status);
        return response.text().then(text => {
          console.error('응답 본문:', text);
          throw new Error(`Failed to fetch. Status: ${response.status}`);
        });
      }

      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        return response.json();
      } else {
        return response.text(); // JSON이 아닌 응답 처리
      }
    })
    .catch(error => {
      console.error('Fetch Error:', error);
      throw error;
    });
};

// 게시글 저장 함수
export const savePost = async (postData) => {
  const token = localStorage.getItem('accessToken'); // 저장된 토큰 불러오기

  return apiFetch('/api/post', {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`, // 인증 토큰 포함
      'Content-Type': 'application/json', // JSON 형식으로 전송
    },
    body: JSON.stringify(postData), // 데이터를 JSON 형식으로 변환
  });
};
