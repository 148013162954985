import React, { useEffect, useState } from 'react';
import './mypage.css'; // CSS 파일 import
import { apiFetch } from './api';
import { useNavigate } from 'react-router-dom';

function MyPage() {
  const [userInfo, setUserInfo] = useState(null);
  const [recentAction, setRecentAction] = useState(null);
  const [requestHistory, setRequestHistory] = useState([]);
  const [acceptHistory, setAcceptHistory] = useState([]);
  const accessToken = localStorage.getItem('accessToken'); // accessToken 가져오기
  const navigate = useNavigate(); // useNavigate 훅 사용

  useEffect(() => {
    // 유저 정보 가져오기
    async function fetchUserInfo() {
      try {
        const response = await apiFetch('/api/user', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });

        if (typeof response === 'object') {
          if (response.code === 200) {
            setUserInfo(response.data); // 유저 정보 설정
          } else {
            console.error('유저 정보 조회 실패', response.message);
          }
        } else {
          console.error('응답이 JSON 형식이 아닙니다:', response);
        }
      } catch (error) {
        console.error('유저 정보 조회 실패', error);
      }
    }

    // 진행 중인 요청/수락 상태 가져오기
    async function fetchRecentAction() {
      try {
        const requestResponse = await apiFetch('/api/request?status=in_progress', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });

        const acceptResponse = await apiFetch('/api/acceptance?status=in_progress', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });

        if (typeof requestResponse === 'object' && typeof acceptResponse === 'object') {
          if (acceptResponse.data.requests.length > 0) {
            setRecentAction({ type: 'accept', detail: acceptResponse.data.requests[0] });
          } else if (requestResponse.data.requests.length > 0) {
            setRecentAction({ type: 'request', detail: requestResponse.data.requests[0] });
          }
        } else {
          console.error('응답이 JSON 형식이 아닙니다:', requestResponse, acceptResponse);
        }
      } catch (error) {
        console.error('진행 중인 요청/수락 상태 조회 실패', error);
      }
    }

    // 요청 내역 및 수락 내역 가져오기
    async function fetchHistory() {
      try {
        const requestResponse = await apiFetch('/api/request', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });

        const acceptResponse = await apiFetch('/api/acceptance', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });

        if (typeof requestResponse === 'object' && typeof acceptResponse === 'object') {
          if (requestResponse.code === 200) {
            setRequestHistory(requestResponse.data.requests); // 요청 내역 설정
          }

          if (acceptResponse.code === 200) {
            setAcceptHistory(acceptResponse.data.requests); // 수락 내역 설정
          }
        } else {
          console.error('응답이 JSON 형식이 아닙니다:', requestResponse, acceptResponse);
        }
      } catch (error) {
        console.error('요청/수락 내역 조회 실패', error);
      }
    }

    fetchUserInfo();
    fetchRecentAction();
    fetchHistory();
  }, [accessToken]);

  return (
    <div className="content-container">
      {/* 유저 프로필 카드 */}
      <div className="box profile-box">
        {userInfo ? (
          <>
            <div className="profile-picture">
              <img src={userInfo.profileImageUrl ? userInfo.profileImageUrl : 'default.png'} alt="프로필 사진" />
            </div>
            <div className="profile-info">
              <div className="profile-name">{userInfo.name}</div>
              <div className="profile-nickname">{userInfo.nickname}</div>
              {/* 수정하기 버튼을 항상 표시 */}
              <button className="edit-button" onClick={() => navigate('/myedit')}>
                수정하기
              </button>
            </div>
          </>
        ) : (
          <div>유저 정보를 불러오는 중입니다...</div>
        )}
      </div>

      {/* 진행 중인 요청/수락 */}
      <div className="box current-box">
        <div className="status-title">진행 중인 요청/수락</div>
        <div className="status-action-row">
          {recentAction ? (
            <>
              <div className="status-detail">
                {recentAction.type === 'accept'
                  ? `${recentAction.detail.nickname}님의 요청 수락중`
                  : '나의 요청 진행 중'}
              </div>
              {/* 진행 중인 상세보기 버튼을 항상 표시 */}
              <button
                className="detail-button"
                onClick={() => navigate(recentAction.type === 'accept' ? '/mycurrentAccept' : '/mycurrentRequest')}
              >
                상세보기
              </button>
            </>
          ) : (
            <div className="status-detail no-action">진행 중인 요청/수락이 없습니다.</div>
          )}
        </div>
        <hr className="divider" />
        <div className="badge">
          <div>🏴 바퀴벌레 학살자</div>
          <div>❤️ 고수</div>
        </div>
      </div>

      {/* 요청 내역 보기 */}
      <div className="box request-history-box">
        <h2>요청 내역 보기</h2>
        <div className="total-count">총 <span style={{ color: 'green' }}>{requestHistory.length}</span>회</div>
        <hr className="divider" />
        {requestHistory.length > 0 ? (
          <ul className="history-list">
            {requestHistory.slice(0, 4).map((item) => (
              <li className="history-item" key={item.postId}>
                <span className="date">{new Date(item.createdAt).toLocaleDateString()}</span>
                <span className="bug-type">{item.bug_type}</span>
                {/* 요청 내역 상세보기 버튼을 항상 표시 */}
                <button
                  className="detail-button"
                  onClick={() => navigate('/myrequest', { state: { postId: item.postId } })}
                >
                  상세보기
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <div>요청 내역이 없습니다.</div>
        )}
      </div>

      {/* 수락 내역 보기 */}
      <div className="box accept-history-box">
        <h2>수락 내역 보기</h2>
        <div className="total-count">총 <span style={{ color: 'blue' }}>{acceptHistory.length}</span>회</div>
        <hr className="divider" />
        {acceptHistory.length > 0 ? (
          <ul className="history-list">
            {acceptHistory.slice(0, 4).map((item) => (
              <li className="history-item" key={item.postId}>
                <span className="date">{new Date(item.createdAt).toLocaleDateString()}</span>
                <span className="bug-type">{item.bug_type}</span>
                <span className="rating">평점: {item.review ? `${item.review.point}/5` : 'N/A'}</span>
                <button className="success-button">
                  {item.review ? (item.review.isSuccess === 'O' ? '성공' : '실패') : '미완료'}
                </button>
                {/* 수락 내역 상세보기 버튼을 항상 표시 */}
                <button
                  className="detail-button"
                  onClick={() => navigate('/myaccept', { state: { postId: item.postId } })}
                >
                  상세보기
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <div>수락 내역이 없습니다.</div>
        )}
      </div>
    </div>
  );
}

export default MyPage;
