import React, { useState } from 'react';

function StepTwo({ updateFormData, goToNextStep, goToPreviousStep }) {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [reward, setReward] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleNext = () => {
    if (title.length < 10 || content.length < 20 || !reward) {
      setErrorMessage('모든 필수 필드를 채워주세요.');
      return;
    }

    const formData = {
      title,
      content,
      reward,
    };

    // 콘솔에 입력 데이터 출력
    console.log('Step Two 입력 데이터:', formData);

    updateFormData(formData);

    // 다음 단계로 이동
    goToNextStep();
  };

  return (
    <div className="form-container">
      <h2>게시글 작성</h2>

      <label>1. 게시글 제목</label>
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="최소 10글자 이상 입력해주세요"
      />

      <label>2. 게시글 본문</label>
      <textarea
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder="최소 20글자 이상 입력해주세요"
      ></textarea>

      <label>3. 보상 입력</label>
      <select value={reward} onChange={(e) => setReward(e.target.value)}>
        <option value="">보상 선택</option>
        <option value="잡's 내공 100">잡's 내공 100</option>
        <option value="잡's 내공 50">잡's 내공 50</option>
        <option value="없음">없음</option>
      </select>

      {errorMessage && <div className="toast-message">{errorMessage}</div>}

      <div className="button-container">
        <button className="button" onClick={goToPreviousStep}>이전</button>
        <button className="button" onClick={handleNext}>다음</button>
      </div>
    </div>
  );
}

export default StepTwo;
