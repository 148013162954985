// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import './styles.css'; 
import Home from './home';
import JobPlease from './jobplease';
import Chat from './chat';
import MyPage from './mypage';
import Modal from './modal';
import Login from './login';  
import Register from './register';
import MyEdit from './myedit'; // myedit 컴포넌트 가져오기
import { apiFetch } from './api';
import { extractTokenFromUrl, storeToken } from './tokenUtils';
//커밋
// URL에서 토큰을 추출하고 저장한 후 홈으로 리다이렉트하는 컴포넌트
function TokenHandler() {
    const navigate = useNavigate();

    useEffect(() => {
        const tokenFromUrl = extractTokenFromUrl();
        if (tokenFromUrl) {
            storeToken(tokenFromUrl, false); // 로그인 토큰 저장
            console.log('로그인 토큰이 URL에서 추출되어 저장되었습니다:', tokenFromUrl);
            navigate('/home', { replace: true }); // 홈으로 리다이렉트하고 기록에 추가하지 않음
        } else {
            console.error('URL에 토큰이 없습니다.');
            navigate('/login'); // 로그인 페이지로 리다이렉트
        }
    }, [navigate]);

    return null; // 렌더링할 내용 없음
}

function App() {
    const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태 관리
    const [modalContent, setModalContent] = useState({ title: '', content: '' }); // 모달 내용 설정
    const [selectedAddress, setSelectedAddress] = useState(null); // 선택된 주소 상태
    const [isLoggedIn, setIsLoggedIn] = useState(false); // 로그인 상태 관리
    const [userName, setUserName] = useState(''); // 사용자 닉네임 상태

    // 컴포넌트가 처음 렌더링될 때 로컬 스토리지에서 토큰을 불러오고 유저 정보 조회
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            console.log('로컬 스토리지에서 불러온 accessToken:', token);
            setIsLoggedIn(true); // 로그인 상태 true로 설정
            getUserInfo(token); // 유저 정보 조회 함수 호출
        } else {
            console.log('로컬 스토리지에 accessToken이 없습니다.');
        }
    }, []); // 컴포넌트가 처음 렌더링될 때 한 번만 실행

    const getUserInfo = async (token) => {
      try {
          const response = await apiFetch('/api/user', {
              method: 'GET',
              headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
              },
          });
    
          console.log('API 응답:', response); // 응답 상태 확인
    
          if (response.code === 200 && response.data) {
              setUserName(response.data.nickname); // 닉네임 설정
              console.log('유저 정보 조회 성공:', response.data);
          } else {
              console.error('유저 정보 조회 실패:', response.message || '응답 데이터에 문제가 있습니다.');
          }
      } catch (error) {
          console.error('유저 정보 조회 중 오류 발생:', error.message);
      }
    };
    
  

    // OAuth 리다이렉션 처리 함수
    const handleOAuthRedirection = () => {
        window.location.href = 'https://api.jobbug.site/oauth2/authorization/google';
    };

    const handleLogout = async () => {
        if (window.confirm("정말 로그아웃 하시겠습니까?")) {
            const accessToken = localStorage.getItem('accessToken');
            if (!accessToken) {
                alert("로그인 상태가 아닙니다.");
                return;
            }

            try {
                const response = await apiFetch('/api/user/logout', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response) {
                    throw new Error("응답이 없습니다.");
                }

                const contentType = response.headers ? response.headers.get('content-type') : null;
                let result = null;

                if (contentType && contentType.includes('application/json')) {
                    result = await response.json();
                } else {
                    console.log("응답이 JSON 형식이 아닙니다.");
                    alert("로그아웃에 성공했습니다.");
                    localStorage.removeItem('accessToken'); 
                    setIsLoggedIn(false);
                    window.location.href = '/login';
                    return;
                }

                if (response.ok && result.code === 200) {
                    alert("성공적으로 로그아웃되었습니다.");
                    localStorage.removeItem('accessToken'); 
                    setIsLoggedIn(false);
                    window.location.href = '/login'; 
                } else {
                    alert(result && result.message ? result.message : "로그아웃에 실패했습니다. 다시 시도해주세요.");
                }
            } catch (error) {
                console.error("로그아웃 중 에러 발생:", error);
                alert("로그아웃에 실패했습니다. 네트워크를 확인해주세요.");
            }
        }
    };

    // 모달 열기 함수
    const openModal = (title, content) => {
        setModalContent({ title, content });
        setIsModalOpen(true); 
    };

    return (
        <Router>
            <div className="app-container">
                <div className="sidebar">
                    <div className="logo">
                        <img src={`${process.env.PUBLIC_URL}/images/jobbugwonlogo.svg`} alt="Jobbug Logo" />
                    </div>
                    <ul className="menu">
                        <li>
                            <Link to="/home">
                                <img src={`${process.env.PUBLIC_URL}/images/homeblackicon.svg`} alt="Home Icon" className="menu-icon" />
                                홈
                            </Link>
                        </li>
                        <li>
                            <Link to="/jobplease">
                                <img src={`${process.env.PUBLIC_URL}/images/jobpleaseicon.svg`} alt="Job Please Icon" className="menu-icon" />
                                잡아주세요
                            </Link>
                        </li>
                        <li>
                            <Link to="/chat">
                                <img src={`${process.env.PUBLIC_URL}/images/chaticon.svg`} alt="Chat Icon" className="menu-icon" />
                                채팅
                            </Link>
                        </li>
                        <li>
                            <Link to="/mypage">
                                <img src={`${process.env.PUBLIC_URL}/images/mypageicon.svg`} alt="My Page Icon" className="menu-icon" />
                                마이페이지
                            </Link>
                        </li>
                    </ul>

                    {/* 정보 카드 섹션 */}
                    <div className="info-cards">
                        <div className="card teal">
                            <div className="card-header">
                                <span>빠른 후기 작성하기</span>
                                <img src={`${process.env.PUBLIC_URL}/images/whitethree.svg`} alt="Option Icon" className="three-icon" />
                            </div>
                            <div className="card-value">2</div>
                            <button className="plus-icon-btn" onClick={() => openModal('빠른 후기 작성하기', '다음의 항목들을 입력해주세요')}>
                                <img src={`${process.env.PUBLIC_URL}/images/plusicon.svg`} alt="Plus Icon" />
                            </button>
                        </div>
                        <div className="card green">
                            <div className="card-header">
                                <span>나한테 작성된 후기 보기</span>
                                <img src={`${process.env.PUBLIC_URL}/images/whitethree.svg`} alt="Option Icon" className="three-icon" />
                            </div>
                            <div className="card-value">5</div>
                            <button className="plus-icon-btn" onClick={() => openModal('나한테 작성된 후기 보기', '다음의 항목들을 입력해주세요')}>
                                <img src={`${process.env.PUBLIC_URL}/images/plusicon.svg`} alt="Plus Icon" />
                            </button>
                        </div>
                        <div className="card yellow">
                            <div className="card-header">
                                <span>진행 중인 요청</span>
                                <img src={`${process.env.PUBLIC_URL}/images/whitethree.svg`} alt="Option Icon" className="three-icon" />
                            </div>
                            <div className="card-value">1</div>
                            <button className="plus-icon-btn" onClick={() => openModal('진행 중인 요청', '진행 중인 요청을 확인할 수 있습니다.')}>
                                <img src={`${process.env.PUBLIC_URL}/images/plusicon.svg`} alt="Plus Icon" />
                            </button>
                        </div>
                    </div>
                </div>

                <div className="main-content-container">
                    <div className="main-content">
                        <header className='header'>
                            <div className="auth-links">
                                {!isLoggedIn ? (
                                    <>
                                        <Link to="/login">로그인</Link>
                                        <a href="#" onClick={handleOAuthRedirection}>회원가입</a>
                                    </>
                                ) : (
                                    <>
                                        <span>{userName}님 환영합니다!</span>
                                        <button onClick={handleLogout} className="logout-btn">로그아웃</button>
                                    </>
                                )}
                            </div>
                        </header>

                        {/* 라우트 설정 */}
                        <Routes>
                            <Route path="/home" element={<Home setSelectedAddress={setSelectedAddress} />} />
                            <Route path="/jobplease" element={<JobPlease />} />
                            <Route path="/chat" element={<Chat />} />
                            <Route path="/mypage" element={<MyPage />} />
                            <Route path="/login" element={<Login isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="/login/success" element={<TokenHandler />} />
                            <Route path="/myedit" element={<MyEdit />} />
                        </Routes>
                    </div>
                </div>

                {isModalOpen && (
                    <Modal title={modalContent.title} content={modalContent.content} onClose={() => setIsModalOpen(false)} />
                )}
            </div>
        </Router>
    );
}

export default App;