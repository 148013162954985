import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './myedit.css'; // 스타일 파일 import
import { apiFetch } from './api'; // API 호출을 위한 유틸리티
import AddressSearch from './AddressSearch'; // 주소 검색 컴포넌트

function MyEdit() {
  const [profileImage, setProfileImage] = useState(null);
  const [nickname, setNickname] = useState('');
  const [address, setAddress] = useState('');
  const [detailAddress, setDetailAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userName, setUserName] = useState(''); // 이름 필드 추가
  const accessToken = localStorage.getItem('accessToken'); // accessToken 가져오기
  const navigate = useNavigate();

  useEffect(() => {
    // 서버에서 기존 사용자 정보를 불러옴
    const fetchUserProfile = async () => {
      try {
        const response = await apiFetch('/api/user', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();
        if (result.code === 200) {
          console.log(result.data); // 반환된 데이터 확인
          const { nickname, addr, detailAddr, phone, name, profileImageUrl } = result.data;
          setNickname(nickname || '');
          setAddress(addr || '');
          setDetailAddress(detailAddr || '');
          setPhoneNumber(phone || '');
          setUserName(name || ''); // 이름 설정
          setProfileImage(profileImageUrl || 'default.png'); // 프로필 이미지 설정
        }
      } catch (error) {
        alert('사용자 정보를 불러오는 데 실패했습니다.');
      }
    };

    fetchUserProfile();
  }, [accessToken]); // 종속성 배열에 accessToken 추가

  // 프로필 사진 업로드 핸들러
  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(URL.createObjectURL(file));
    }
  };

  // 닉네임 중복 확인
  const checkNicknameDuplicate = async () => {
    try {
      const response = await apiFetch(`/api/user/duplicate?nickname=${nickname}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      const result = await response.json();
      if (result.code === 200) {
        alert('사용 가능한 닉네임입니다.');
      }
    } catch (error) {
      alert('중복된 닉네임입니다.');
    }
  };

  // 회원 정보 수정 요청
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!nickname || !phoneNumber || !address || !detailAddress || phoneNumber.length < 9) {
      alert('모든 필드를 올바르게 입력해주세요.');
      return;
    }
    const formData = new FormData();
    formData.append('nickname', nickname);
    formData.append('addr', address);
    formData.append('detailAddr', detailAddress);
    formData.append('phone', phoneNumber);
    if (profileImage && typeof profileImage !== 'string') formData.append('profile', profileImage);

    try {
      const response = await apiFetch('/api/user', {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
        body: formData,
      });
      const result = await response.json();
      if (result.code === 200) {
        alert('회원 정보 수정 성공');
        navigate('/mypage');
      } else {
        alert('회원 정보 수정 실패');
      }
    } catch (error) {
      alert('서버 오류가 발생했습니다.');
    }
  };

  return (
    <div className="myedit-container">
      <div className="profile-section">
        <div className="photo-box">
          <img
            src={profileImage || 'default.png'}
            alt="프로필 사진"
            className="profile-image"
          />
          <input type="file" accept="image/*" onChange={handleProfileImageChange} className="upload-button" />
        </div>
        <div className="photo-buttons">
          <button onClick={() => setProfileImage('default.png')} className="remove-photo-btn">Remove</button>
          <button className="change-photo-btn">사진 변경하기</button>
        </div>
        <div className="profile-info">
          <h2>{userName}</h2> {/* 이름 출력 */}
          <p className="nickname">{nickname}</p> {/* 닉네임 출력 */}
        </div>
      </div>

      <form onSubmit={handleSubmit} className="profile-edit-form">
        <div className="form-group">
          <label>이름</label>
          <input type="text" value={userName} readOnly />
        </div>

        <div className="form-group">
          <label>닉네임</label>
          <input
            type="text"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            maxLength={8}
            required
          />
          <button type="button" onClick={checkNicknameDuplicate} className="check-btn">중복 확인</button>
        </div>

        <div className="form-group">
          <label>주소</label>
          <AddressSearch onSelectAddress={setAddress} /> {/* AddressSearch로 주소 검색 */}
          <input type="text" value={address} readOnly />
        </div>

        <div className="form-group">
          <label>상세 주소</label>
          <input type="text" value={detailAddress} onChange={(e) => setDetailAddress(e.target.value)} required />
        </div>

        <div className="form-group">
          <label>전화번호</label>
          <input
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            pattern="[0-9]{9,}"
            required
          />
        </div>

        <div className="form-buttons">
          <button type="submit" className="save-btn">저장</button>
          <button type="button" onClick={() => navigate('/mypage')} className="cancel-btn">취소</button>
          <button type="button" className="delete-account-btn">계정탈퇴</button>
        </div>
      </form>
    </div>
  );
}

export default MyEdit;
