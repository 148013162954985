import React, { useState } from 'react';

function StepThree({ updateFormData, goToNextStep, goToPreviousStep }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleNext = () => {
    // 이미지가 선택되지 않은 경우에도 처리
    const formData = {
      originImageUrl: selectedFile ? URL.createObjectURL(selectedFile) : null,
      editedImageUrl: null,
      selectedFile, // 실제 파일 객체를 추가하여 백엔드로 전송할 수 있도록 준비
    };

    // 콘솔에 입력 데이터 출력
    console.log('Step Three 입력 데이터:', formData);

    updateFormData(formData);
    goToNextStep(); // 다음 단계로 이동
  };

  return (
    <div className="form-container">
      <h2>이미지 업로드 (선택 사항)</h2>
      <input type="file" onChange={handleFileChange} />
      {errorMessage && <div className="toast-message">{errorMessage}</div>}
      <button className="button" onClick={goToPreviousStep}>이전</button>
      <button className="button" onClick={handleNext}>다음</button>
    </div>
  );
}

export default StepThree;
